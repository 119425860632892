import React from "react";
import "../css/Timeline.css";
import CTA from "../components/CTA";
import ContactForm from "./contactForm";
import { socialLinks } from "../components/socialLinks";
import "../css/Contact.css";
import "../css/SocialLink.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// DO NOT EDIT THIS COMPONENT (BELOW)
function Contact() {
  return (
    <section id="contact" className="contact-section">
      <CTA />
      <div className="contact-container">
        <div className="contact-content">
          <ContactForm />
          {/* Social Links */}
          <ul className="social-links">
            {socialLinks.map((link) => {
              const { src, id, icon } = link;
              return (
                <li className="social-link-container" key={id}>
                  <a href={src} className="social-link">
                    <FontAwesomeIcon icon={icon} />
                  </a>
                </li>
              );
            })}
          </ul>
          {/* Social Links */}
        </div>
      </div>
    </section>
  );
}
// DO NOT EDIT THIS COMPONENT (ABOVE)

export default Contact;
