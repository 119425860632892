import React from "react";
import "../css/Skills.css";

function Skills() {
  return (
    <section id="skills" className="skills-section">
      {/* EDIT THE COMPETENCIES/SKILLS CONTENT HERE....(BELOW) */}
      <div className="skills-container">
        {/* TOP CARDS */}
        <h2 className="skills-header">Competencies</h2>
        <div className="skills-content-top">
          <article className="card">
            <h2 className="skills-card-header">Languages</h2>
            <p className="card-content">
              Python, Solidity, C++, Java, C, JavaScript, C#, Bash, MySQL,
              Oracle PL/SQL, Assembly, HTML, CSS, JSON, YAML, MATLAB
            </p>
          </article>
          <article className="card">
            <h2 className="skills-card-header">Project Managment</h2>
            <p className="card-content">
              SCRUM, Maven, GitHub, Presentation Skills, Team
              Leadership/Management, Cross-Team Communication
            </p>
          </article>
        </div>
        {/* TOP CARDS */}
        {/* BOTTOM CARDS */}
        <div className="skills-content-bottom">
          <article className="card">
            <h2 className="skills-card-header">Relevant Courses</h2>
            <p className="card-content">
              Data StructuresSoftware, SystemsComputer, OrganizationSystems,
              Discrete Structures
            </p>
          </article>
          <article className="card">
            <h2 className="skills-card-header">Coding Methodologies</h2>
            <p className="card-content">
              Test-Driven-Development, OWASP security knowledge, Agile, Clean
              Code, Useful Comments
            </p>
          </article>
          <article className="card">
            <h2 className="skills-card-header">Interest</h2>
            <p className="card-content">
              Software Development, Blockchain, Trading, Machine Learning, Data
              Visualization
            </p>
          </article>
          <article className="card">
            <h2 className="skills-card-header">Development</h2>
            <p className="card-content">
              Linux, Virtual Machines, Docker, JSON, Junit
            </p>
          </article>
        </div>
        {/* BOTTOM CARDS */}
      </div>
      {/* EDIT THE COMPETENCIES/SKILLS CONTENT HERE....(ABOVE) */}
    </section>
  );
}

export default Skills;
