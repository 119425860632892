import React from "react";
import "../css/Timeline.css";
import { events } from "./events";

function Timeline() {
  return (
    <section id="timeline" className="timeline-section">
      <div className="timeline-content">
        <h2 className="timeline-header">Timeline</h2>
        <p className="timeline-text">Your Experience Overview</p>
      </div>
      <div className="timeline">
        {events.map((event, i) => {
          const { id, date, title, rec, description } = event;
          const isOdd = i % 2 === 0;
          return (
            <div
              key={id}
              className={`timeline-container ${isOdd ? "left" : "right"}`}
            >
              <div className="container">
                <div className="content">
                  <h4 className="event-date">{date}</h4>
                  <h4 className="event-title">{title}</h4>
                  <h4 className="event-rec">{rec}</h4>
                  <p className="event-text">{description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Timeline;
