// UPDATE THE PROJECT IMAGES SAVED IN ASSETS/IMAGE FOLDER HERE.....
import img1 from "../assets/images/proj-1.jpg";
import img2 from "../assets/images/proj-2.jpg";
import img3 from "../assets/images/proj-3.jpg";
import img4 from "../assets/images/proj-1.jpg";
import img5 from "../assets/images/proj-2.jpg";
import img6 from "../assets/images/proj-3.jpg";
// UPDATE THE PROJECT IMAGES SAVED IN ASSETS/IMAGE FOLDER HERE.....

// ADD THE IMAGES TO THE "image" section of the object. Also you can edit/add any of the project content to this section to update the DOM (ABOVE)
const projectContent = [
  {
    id: 1,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin", "Unity", "Java", "Kotlin"],
    image: img1,
    image2: img2,
    repoLink: "https://github.com/FindingDorri",
  },
  {
    id: 2,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin"],
    image: img2,
    repoLink: "https://github.com/FindingDorri",
  },
  {
    id: 3,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin"],
    image: img3,
    repoLink: "https://github.com/FindingDorri",
  },
  {
    id: 4,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin"],
    image: img4,
    repoLink: "https://github.com/FindingDorri",
  },
  {
    id: 5,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin"],
    image: img5,
    repoLink: "https://github.com/FindingDorri",
  },
  {
    id: 6,
    title: "Project Title",
    subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    stack: ["Unity", "Java", "Kotlin"],
    image: img6,
    repoLink: "https://github.com/FindingDorri",
  },
];
// ADD THE IMAGES TO THE "image" section of the object. Also you can edit/add any of the project content to this section to update the DOM (ABOVE)

export default projectContent;
