import React from "react";
import "../css/Contact.css";

const ResumeDownload = () => {
  // TO SAVE YOUR PDF RESUME LOCATE THE FILE IN PUBLIC FOLDER AND SWAP FOR NEW RESUME FILES THEN UPDATE PATH (BELOW)
  const resumeUrl =
    process.env.PUBLIC_URL + "/resumeFile/test-resume-download.pdf";
  // TO SAVE YOUR PDF RESUME LOCATE THE FILE IN PUBLIC FOLDER AND SWAP FOR NEW RESUME FILES THEN UPDATE PATH (ABOVE)
  return (
    <div>
      <a href={resumeUrl} download>
        <div className="cta-btn">
          Download <br /> Resume
        </div>
      </a>
    </div>
  );
};

function CTA() {
  return (
    <div className="cta-container">
      <div className="cta-content">
        <h2 className="cta-text">Am I A Great Fit For Your Company?</h2>
        <ResumeDownload />
      </div>
    </div>
  );
}

export default CTA;
