import React, { useState } from "react";
import "../css/Projects.css";

import projectContent from "../components/projectContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ModalContent = ({ isOpen, closeModal, project }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <section className="card-overlay">
      <div className="overlay-container">
        <div className="overlay-content">
          <FontAwesomeIcon
            className="close-icon"
            icon={faTimes}
            onClick={closeModal}
          />
          <h2 className="overlay-title">{project.title}</h2>
          <p className="overlay-text">{project.subText}</p>
          <p className="overlay-text">{project.description}</p>
          <img
            className="overlay-img"
            src={project.image}
            alt={project.image}
          />
          <img
            className="overlay-img"
            src={project.image2}
            alt={project.image2}
          />
          {project.image2 ? <p>The Team</p> : null}
          <a href={project.repoLink} className="repo-link">
            View Project on Github
          </a>
        </div>
      </div>
    </section>
  );
};

function Projects() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
    console.log("open modal");
  };

  const closeModal = () => {
    setSelectedProject(null);
    setModalOpen(false);
  };

  return (
    <section id="projects" className="projects-section">
      {/* DO NOT EDIT THIS CODE (BELOW) */}
      <ModalContent
        isOpen={modalOpen}
        closeModal={closeModal}
        project={selectedProject}
      />
      {/* DO NOT EDIT THIS CODE (ABOVE) */}
      {/* PROJECT SECTION TITLE AND DESCRIPTION TAGS (BELOW) */}
      <div className="projects-container">
        <div className="projects-header">Project Title</div>
        <p className="projects-text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
          laudantium facere libero quae provident vitae tempore, aliquid
          assumenda.
        </p>
        {/* PROJECT SECTION TITLE AND DESCRIPTION TAGS (ABOVE) */}
        {/* DO NOT EDIT THIS CODE (BELOW) */}
        <div className="projects-content">
          {projectContent.map((project) => (
            <article
              className="project-card"
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), URL(${project.image})`,
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                backgroundSize: "800px",
                backgroundPosition: "center",
              }}
              key={project.id}
              onClick={() => openModal(project)}
            >
              <div className="card-container">
                <h2 className="projects-card-header">{project.title}</h2>
                <p className="card-content">{project.subText}</p>
                <ul className="tech-stack">
                  {project.stack.map((techElement) => (
                    <li className="tech-stack-container" key={techElement}>
                      {techElement}
                    </li>
                  ))}
                </ul>
              </div>
            </article>
          ))}
        </div>
        {/* DO NOT EDIT THIS CODE (ABOVE) */}
      </div>
    </section>
  );
}

export default Projects;
