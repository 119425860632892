import React, { useState } from "react";
import { useEffect } from "react";
import { navbarLinks } from "../components/navbarLinks";
import "../css/Navbar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

// DO NOT EDIT THE CODE/COMPONENTS (BELOW)
const NavbarTransparency = () => {
  const handleScroll = () => {
    const value = window.scrollY;
    const navbar = document.querySelector(".navbar");
    const opacity = (value / window.innerHeight) * 6;
    navbar.style.background = `rgba(0, 0, 0, ${opacity})`;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
};

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    // Menu not open
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    // USE STATE TO CONTROL THE ICONS FOR THE NAV
    <nav className={`navbar ${isMenuOpen ? "show" : ""}`}>
      <div className="navbar-container">
        <a href="./index" style={{ textDecoration: `none` }}>
          <h2 className="navbar-brand">Tho Le</h2>
        </a>
        <FontAwesomeIcon
          className={`close-icon-nav ${isMenuOpen ? "show-close" : ""}`}
          onClick={toggleMenu}
          icon={faTimes}
        />
        <FontAwesomeIcon
          className={`toggle-icon-nav ${isMenuOpen ? "hide-toggle" : ""}`}
          onClick={toggleMenu}
          icon={faBars}
        />
        <ul className={`navbar-links ${isMenuOpen ? "show" : ""}`}>
          {navbarLinks.map((link) => {
            const { text, src, id } = link;
            return (
              <li className="link-container" key={id}>
                <a href={src} className="link">
                  {text}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {NavbarTransparency()}
    </nav>
  );
}
// DO NOT EDIT THESE COMPONENTS/CODE (ABOVE)
export default Navbar;
