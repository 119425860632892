import React from "react";

// EDIT PROFILE IMAGE HERE - Save in path and update
import aboutImg from "../assets/images/about-img.jpeg";
// EDIT PROFILE IMAGE HERE - Save in path and update
import "../css/About.css";

// TO EDIT THE SOCIAL LINK VISIT THE socialLinks.js files
import { socialLinks } from "../components/socialLinks";

function About() {
  return (
    <section id="about" className="about-section">
      <div className="about-container">
        <div className="about-left">
          <img className="about-portfolio-img" src={aboutImg} alt={aboutImg} />
        </div>
        <div className="about-right">
          {/* EDIT ABOUT-TEXT BELOW  */}
          <h2 className="about-header">About Me</h2>
          <p className="about-text">
            I hold a Bachelor's degree in Computer Science with a minor in
            Mathematics from Metropolitan State University of Denver. My passion
            lies in software design and development, where I strive to create
            innovative solutions. I have a keen interest in cutting-edge
            technologies, particularly Artificial Intelligence and Natural
            Language Processing, and I continuously seek to better my
            understanding in these areas.
          </p>
          <h2 className="about-edu-header">Education</h2>
          <ul className="about-list">
            <li className="about-list-text">
              <b>Metropolitan State University Of Denver</b> <br />
              Bachelor of Science in Computer Science <b>Summa Cum Laude</b>
            </li>
            <li className="about-list-text">
              <b>College of Health and Applied Sciences</b> <br /> Outstanding
              Computer Science Student Award 2022 - 2023
            </li>
          </ul>
          {/* EDIT ABOUT-TEXT ABOVE  */}

          {/* DO NOT EDIT THIS SECTION BELOW */}
          <ul className="social-links">
            {socialLinks.map((link) => {
              const { text, src, id } = link;
              return (
                <li className="social-link-container" key={id}>
                  <a href={src} className="social-link">
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
          {/* DO NOT EDIT THIS SECTION ABOVE */}
        </div>
      </div>
    </section>
  );
}

export default About;
