import React from "react";

const ContactForm = () => {
  return (
    <div className="form-container">
      <h2 className="form-header">Contact Me</h2>
      <p className="form-text">Use The Form To Contact Me Directly</p>
      {/* ATTACH YOUR EMAIL TO THE "ACTION" METHOD OR VISIT https://formsubmit.co/ for setup (BELOW) */}
      <form
        className="form"
        action="https://formsubmit.co/your@email.com"
        method="POST"
      >
        {/* ATTACH YOUR EMAIL TO THE "ACTION" METHOD OR VISIT https://formsubmit.co/ for setup (ABOVE) */}
        {/* DO NOT EDIT THE CODE BELOW */}
        <div className="form-content">
          <div className="form-left-content">
            <input
              className="form-field"
              type="text"
              name="name"
              required
              placeholder="NAME"
            />
            <input
              className="form-field"
              type="email"
              name="email"
              required
              placeholder="EMAIL"
            />
            <input
              className="form-field"
              type="number"
              name="phone"
              required
              placeholder="PHONE NUMBER"
            />
          </div>
          <textarea
            className="form-field textarea"
            name="message"
            required
            placeholder="MESSAGE"
          ></textarea>
        </div>

        <button className="form-btn" type="submit">
          Send
        </button>
        {/* DO NOT EDIT THE CODE ABOVE */}
      </form>
    </div>
  );
};

export default ContactForm;
