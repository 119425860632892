import React from "react";
import "../css/Footer.css";

// DO NOT EDIT THIS COMPONENT (BELOW)
function Footer() {
  return (
    <section className="footer-section">
      <div className="footer-container">
        <div className="footer-content">
          <p className="designer-main">
            Designed By{" "}
            <a
              href="https://www.williamhmoody.com"
              className="designer"
              rel="noreferrer"
              target="_blank"
            >
              William Hellems-Moody
            </a>
          </p>
          <p>© 2023 Copyright Tho Le | All Rights Reserved</p>
        </div>
      </div>
    </section>
  );
}
// DO NOT EDIT THIS COMPONENT (ABOVE)

export default Footer;
