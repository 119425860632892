// FONTAWESOME ICONS - FOR MORE ICON VISIT FONT AWESOME AND VIEW SEARCH FOR THE ICONS (ADD THE ICONS NAME BELOW AND ON THE "socialLinks" OBJECT.
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

// CAN ADD MORE LINKS TO THIS SECTION AS NEEDED.
export const socialLinks = [
  {
    id: 1,
    text: "GitHub",
    src: "https://github.com/FindingDorri",
    icon: faGithub,
  },
  {
    id: 2,
    text: "LinkedIn",
    src: "https://www.linkedin.com/in/tle67/",
    icon: faLinkedinIn,
  },
];
