import React from "react";
import "../css/Hero.css";
import { socialLinks } from "../components/socialLinks";

// EDIT PROFILE IMAGE HERE...SAVE TO IMAGE ASSETS AND UPDATE PATH
import profileImage from "../assets/images/hero-img.jpeg";
// EDIT PROFILE IMAGE HERE...SAVE TO IMAGE ASSETS AND UPDATE PATH

function Hero() {
  return (
    <section className="hero-section" key="hero-section">
      <HeroContent key="hero-content" />
    </section>
  );
}

const HeroContent = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <img className="portfolio-img" src={profileImage} alt={profileImage} />

        {/* EDIT THE TEXT CONTENT (BELOW) */}
        <h2 className="portfolio-name">Tho Le</h2>
        <h3 className="portfolio-title">
          Software Engineer • Graduate Student • Coffee Enthusiast
        </h3>
        {/* EDIT THE TEXT CONTENT (BELOW) */}

        {/* DO NOT EDIT THE CODE (BELOW) */}
        <ul className="social-links">
          {socialLinks.map((link) => {
            const { text, src, id } = link;
            return (
              <li className="social-link-container" key={id}>
                <a href={src} className="social-link">
                  {text}
                </a>
              </li>
            );
          })}
        </ul>
        {/* DO NOT EDIT THE CODE (ABOVE) */}
      </div>
    </div>
  );
};

export default Hero;
